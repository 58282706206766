.horizontal-slider {
  width: 100%;
  height: 30px;
}

.vertical-slider {
  height: 100%;
  width: 20px;
}

.cs-open-slider.cs-open-slider-0 {
  background: rgba(0, 255, 0);
}

.cs-close-slider.cs-close-slider-0 {
  background: rgba(255, 0, 0);
}

.activation-open-slider.activation-open-slider-0 {
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 3px,
    rgba(0, 199, 0) 3px,
    rgba(0, 199, 0) 6px
  );
}

.activation-close-slider.activation-close-slider-0 {
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 3px,
    rgba(199, 0, 0) 3px,
    rgba(199, 0, 0) 6px
  );
}

.activation-open-proportional-slider.activation-open-proportional-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 3px,
    rgba(0, 199, 0) 3px,
    rgba(0, 199, 0) 6px
  );
}

.activation-close-proportional-slider.activation-close-proportional-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 3px,
    rgba(199, 0, 0) 3px,
    rgba(199, 0, 0) 6px
  );
}

.power-slider.power-slider-0 {
  background: rgba(255, 187, 13);
}

.speed2-open-slider.speed2-open-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 4px,
    rgba(0, 145, 0) 4px,
    rgba(0, 145, 0) 8px
  );
}

.speed2-close-slider.speed2-close-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 4px,
    rgba(145, 0, 0) 4px,
    rgba(145, 0, 0) 8px
  );
}

.speed3-open-slider.speed3-open-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 8px,
    rgba(0, 95, 0) 7px,
    rgba(0, 95, 0) 14px
  );
}

.speed3-close-slider.speed3-close-slider-0 {
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 8px,
    rgba(95, 0, 0) 7px,
    rgba(95, 0, 0) 14px
  );
}

.track-default.track-default-0 {
  background: #3788b3;
}

.track-default.track-default-2 {
  background: #3788b3;
}

.track {
  top: 14px;
  height: 5px;
  background: #aaaaaa;
}

.track-vertical {
  left: 7px;
  width: 5px;
  background: #aaaaaa;
}

.track-1 {
  background: #aaaaaa;
}

.track-vertical-1 {
  background: #aaaaaa;
}

.thumb {
  top: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #33499c;
  cursor: pointer;
}

.thumb-vertical {
  bottom: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #33499c;
  cursor: pointer;
}

.thumb:focus {
  border: none;
}

.thumb-vertical:focus {
  border: none;
}

.thumb:focus-visible {
  outline: none;
}

.thumb-vertical:focus-visible {
  outline: none;
}
