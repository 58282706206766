.background-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #efefef;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background-image: url('assets/hand_blue_ball_3.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  transform: scaleX(-1);
}
