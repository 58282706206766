.device-usage {
  width: 100%;
}

.device-usage .k-animation-container {
  width: 100%;
}

.k-child-animation-container {
  width: 100%;
}

.k-child-animation-container > div {
  width: 100%;
}
